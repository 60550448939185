
.battleDiv {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background:url('./img/battle_forest.jpg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
}

.flipped {
    transform: scaleX(-1);
    height: 300px !important;
    margin-left: 50px !important;
    margin-bottom: -50px !important;
}

/* @keyframes bg-animation {
    0% {
        background-position: left;
    }
    100%{
        background-position: right;
    }
} */


.player2{

    margin-bottom: 20px;
    position:absolute;
    top: 50px;
    right: 15%;
    margin-right: 5%;
}

.player1{

    position:absolute;
    bottom:30px;
    left: 12%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    margin-bottom: 50px;
    margin-left: 30px;

}

.button2{
    position:absolute;
    bottom:150px;
    left: 21%;
    width: 170px;
    height: 40px;
}
button:hover {
    background-color:tomato;
  }

.button1{
    position:absolute;
    bottom: 110px;
    left: 21%;
    width: 170px;
    height: 40px;
}
.button3{
    position:absolute;
    bottom: 70px;
    left: 21%;
    width: 170px;
    height: 40px;
}

button {
    background-color: #e2b00a;
    border: none;
    color: white;
    /* padding: 15px 32px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
      background-color: grey;
  }

  .progress {
    
    background-color: rgb(216, 196, 14);
    position: relative;
  }