.readyScreen {
/*     background: linear-gradient(90deg, rgba(21,255,31,1) 0%,rgba(0,113,15,1) 100%);
    background-size: 400% !important;
    animation: bg-animation 15s infinite alternate; */
    padding: 0px;
    margin: 0px;
    height: 100vh !important;
    width: 100vw !important;
    text-align: center;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    justify-content: center;
}

/*Video  section */
.VideoSection {
    position: absolute;
    width: 100% !important;
    height: 100vh !important;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
  }

@keyframes bg-animation {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

.versus {
    margin-top: -32% !important;
    position: relative;
    z-index: 2;
    font-size: 50px !important;
}

.pokeName {
    position: relative;
    z-index: 1;
}

.readyScreenTitle {
    margin-top: 50px !important;
    position: relative;
    z-index: 1;
}

.myimage2{
    color: red;
    transition-duration: 100ms;
    transition-property: transform;
    transition-timing-function: ease;
    animation: spin 0.05s linear infinite;
    position: relative;
    z-index: 1;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);

    }
    100%{
        transform: rotate(360deg);
    }
}

.myimage1{
    animation: rotatedFlipped 0.3s linear infinite alternate;
}
@keyframes rotated {
    0% {
        transform: rotate(15deg);
    }
    100%{
        transform: rotate(-15deg);
    }
}

@keyframes rotatedFlipped {
    0% {
        transform: rotate(15deg) scaleX(-1);
    }
    100%{
        transform: rotate(-15deg) scaleX(-1);
    }
}
.myimage3{
    animation: rotated 0.35s linear infinite alternate-reverse;
}