/* @import url(http://db.onlinewebfonts.com/c/f4d1593471d222ddebd973210265762a?family=Pokemon); */

@font-face {font-family: "Pokemon"; 
    src: url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.eot"); 
    src: url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.eot?#iefix") format("embedded-opentype"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.woff2") format("woff2"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.woff") format("woff"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.ttf") format("truetype"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.svg#Pokemon") format("svg");
        } 

.leaderboard {
  position: absolute;
  top: 35%;
  left: 10%;
  bottom: 50%;
}

.saveButton {
  background-color: transparent !important;
}

.inputName {
  text-align: center;
  width: 50px;
  background-color: transparent;
}

.saveScore {
  position: absolute;
  top: 35%;
  right: 10%;
  bottom: 50%;
}

.startAgainButton {
cursor: pointer;
border: 2px solid rgb(1, 123, 160);
border-radius: 20px;
padding: 0px 20px;
}

.pokefontWinner {
    font-size: 3rem;
    font-family: 'Pokemon', sans-serif;
    letter-spacing: 1rem;
    text-transform: uppercase;
    
    background: -webkit-linear-gradient(90deg, rgb(16, 15, 122) 0%, rgb(1, 234, 255) 100%);;
    -webkit-background-clip: text;
    -webkit-text-stroke: .75rem transparent;
/*     stroke-linecap: round; */
    color: #ffe600;


    text-shadow:
    5px 5px 0 rgb(0, 130, 170),
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000; 
    
  }

  .pokefontStart {
    font-size: 1.5rem;
    font-family: 'Pokemon', sans-serif;
    letter-spacing: .6rem;
    text-transform: uppercase;
    
    background: -webkit-linear-gradient(90deg, rgb(6, 6, 51) 0%, rgb(1, 234, 255) 100%);;
    -webkit-background-clip: text;
    -webkit-text-stroke: .4rem transparent;
/*     stroke-linecap: round; */
    color: #ffe600;


    text-shadow:
    5px 5px 0 rgb(16, 80, 100),
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000; 
    
  }


*,
*::after,
*::before {
  padding: 0;
  margin: 0;
}

/*
    Video BG
*/

/*outer wrapper*/
.videoBg {
  height: 100vh !important;
  width: 100vw !important;
  text-align: center;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}
/*Video  section */
.VideoSection {
  position: absolute;
  width: 100% !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
/*  Overlay */
.VideoOverlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1;
  opacity: 0.2;
}
/*  content */
.VideoContent {
  z-index: 2;
  position: static;
}

.Container {
  width: 90%;
  margin: 0 auto !important;
}

.start {
  flex-direction: row;
  justify-content: center;
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.start img {
  width: 50px;
  margin: 0px 20px;
}
.start h1 {
  animation: animatedtext 1s linear infinite alternate;
}
/*
    logo
*/
@keyframes animatedtext {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateLogo {
  0% {
    /* opacity: 0; */
    transform: scale(0, 0);
  }
  5% {
    transform: scale(1, 1);
  }
  /* 50% {
    transform: scale(2, 2);
  }
  75% {
    transform: scale(2, 2);
  } */
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

.logo img {
  animation: animateLogo 10s linear infinite;
}

/*Media*/
@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .logo img {
    width: 300px;
  }
  .start img {
    width: 30px;
    margin: 0px 20px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
  .logo img {
    width: 600px;
  }
  .start img {
    width: 80px;
    margin: 0px 20px;
  }
}

/* .startFont {
    position: relative;
    overflow: hidden;
    width: auto;
    height: auto;
    display: inline-block;
    
    margin: 25px 0 25px 25px;
    border-radius: 5px;
    color: transparent;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    font-family: sans-serif;
  }
  
  .startFont:after {
    animation: shine 5s ease-in-out  infinite;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
  
  .startFont:active:after {
    opacity: 0;
  }
  
  @keyframes shine{
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  } */