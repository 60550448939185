*,
*::after,
*::before {
  padding: 0;
  margin: 0;
}

.fiberStart {
  position: absolute;
  z-index: 1;
}

/*
    Video BG
*/

/*outer wrapper*/
.videoBg {
  height: 100vh !important;
  width: 100vw !important;
  text-align: center;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}
/*Video  section */
.VideoSection {
  position: absolute;
  width: 100% !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
/*  Overlay */
.VideoOverlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1;
  opacity: 0.2;
}
/*  content */
.VideoContent {
  z-index: 2;
  position: static;
}

.loginScreen {
  text-align: center;
}

.loginScreen input {
  border: 2px solid black;
  border-radius: 5px;
  width: 200px;
  outline: none;
  margin-bottom: 5px;
}

.loginScreen button {
  border: 2px solid black;
  color: black;
  margin: 5px;
  padding: 3px 25px;
  border-radius: 5px;
}

.loginScreen button:hover {
  background-color: lightcoral !important;
}


.submitButton {
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: chartreuse;
}
.submitButton:hover {
  background-color: lightcoral;
}

.Container {
  width: 90%;
  margin: 0 auto !important;
}

.start {
  flex-direction: row;
  justify-content: center;
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.start img {
  width: 50px;
  margin: 0px 20px;
}
.start h1 {
  animation: animatedtext 1s linear infinite alternate;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgb(80, 112, 255), 0 0 20px rgb(80, 112, 255), 0 0 30px rgb(80, 112, 255), 0 0 40px rgb(80, 112, 255), 0 0 50px rgb(80, 112, 255), 0 0 60px rgb(80, 112, 255), 0 0 70px rgb(80, 112, 255);
  color: white;
  position: relative;
  z-index: 99999;
}
/*
    logo
*/
@keyframes animatedtext {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateLogo {
  0% {
    /* opacity: 0; */
    transform: scale(0, 0);
  }
  5% {
    transform: scale(1, 1);
  }
  /* 50% {
    transform: scale(2, 2);
  }
  75% {
    transform: scale(2, 2);
  } */
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

.logo img {
  animation: animateLogo 10s linear infinite;
  position: relative;
  z-index: 99999;
}
.ball {
  position: relative;
  z-index: 99999;
}

/*Media*/
@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .logo img {
    width: 300px;
  }
  .start img {
    width: 30px;
    margin: 0px 20px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
  .logo img {
    width: 600px;
  }
  .start img {
    width: 80px;
    margin: 0px 20px;
  }
}

/* .startFont {
    position: relative;
    overflow: hidden;
    width: auto;
    height: auto;
    display: inline-block;
    
    margin: 25px 0 25px 25px;
    border-radius: 5px;
    color: transparent;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    font-size: 12px;
    font-family: sans-serif;
  }
  
  .startFont:after {
    animation: shine 5s ease-in-out  infinite;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
  
  .startFont:active:after {
    opacity: 0;
  }
  
  @keyframes shine{
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
    }
  } */