
@font-face {font-family: "Pokemon"; 
    src: url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.eot"); 
    src: url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.eot?#iefix") format("embedded-opentype"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.woff2") format("woff2"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.woff") format("woff"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.ttf") format("truetype"), 
         url("//db.onlinewebfonts.com/t/f4d1593471d222ddebd973210265762a.svg#Pokemon") format("svg");
        } 

.pokefont {
    /* font-size: 100px; */
    font-family: 'Pokemon', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;

    /* background: -webkit-linear-gradient(90deg, rgb(16, 15, 122) 0%, rgb(1, 234, 255) 100%);; */
    -webkit-background-clip: text;
    -webkit-text-stroke: 26px transparent;
/*     stroke-linecap: round; */
    color: #ffe600 !important;
    text-shadow:
    2px 2px 0 rgb(0, 0, 0),
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000 !important;  
  }